import { SESSION_LOADER, GET_SESSION,SESSION_CREATE } from "../types";

const initialState = {
  loading: false,
  getSession: [],
};
//geSession and loading are the states in the redux store.

export default function sessionReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case SESSION_LOADER:
      return {
        ...state,
        loading: payload,
      };
    case GET_SESSION:
      return {
        ...state,
        getSession: payload,
      };
    case SESSION_CREATE:
      return {
        ...state,
        loading: payload,
      };
    // case DELETE_SESSION:
    //   return {
    //     ...state,
    //   };

    default:
      return { ...state };
  }
}
