// validationSchema.js
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  Name: Yup.string().required('Name is required').matches(/^[a-zA-Z0-9_. ]+$/, 'Name should contain only alphanumeric characters and (_ .)')
  .trim(),
  Email: Yup.string().email('Invalid email').required('Email is required'),
  Specialization: Yup.string().required('Specialization is required').matches(/^[A-Za-z,& ]+$/, 'Specialization should contain only alphabets and (, &)')
  .trim(),
  PhoneNo: Yup.string().required('Phone number is required'),
  Password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
});

export default validationSchema;
