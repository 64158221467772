import React, { useState, useEffect } from "react";
import {
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
import { createSessionAction } from "store/actions/SessionAction";
import VideoRecorder from "react-video-recorder";
import { v4 as uuidv4 } from "uuid";
import { useSelector, useDispatch } from "react-redux";
import { getSettingAction } from "store/actions/SettingAction";
import SaveSessionModal from "components/modals/SaveSessionModal";
import { getDocsAction} from "store/actions/DocsAction";
import { getAppointmentAction } from "store/actions/AppointmentAction";

const SessionStartModal = ({ dataList, closeModal }) => {
  const DoctorId = useSelector((state) => state.authUser?.user?.DoctorId);
  const { loading } = useSelector((state) => state.session);
  const { getSetting } = useSelector((state) => state.setting);
  const dispatch = useDispatch();
  const [recordedVideo, setRecordedVideo] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(loading);
  const [isNotSaving, setIsNotSaving] = useState(loading);
  useEffect(() => {
    dispatch(getSettingAction(DoctorId));
  }, [DoctorId]);
  const handleRecordingComplete = (videoBlob) => {
    
    const filename = uuidv4() + ".mp4";
    let tempFile = new File([videoBlob], filename, { type: "video/mp4" });
    setRecordedVideo(tempFile);
    
    setModalOpen(true);
  };

  const SaveVideo = () => {
    setIsSaving(false);
    setIsNotSaving(true)
    if (recordedVideo) {
      const formData = new FormData();
      formData.append("File", recordedVideo);
      let payload = {
        SaveVideo: true,
        UniqueAppointmentId: dataList?.UniqueAppointmentId,
      };
      

      dispatch(
        createSessionAction(payload, formData, () => {
          setModalOpen(false)
          closeModal()
          dispatch(getAppointmentAction(DoctorId));
          dispatch(getDocsAction(DoctorId));
        })
      );
    }
  };
  const NotSavedVideo = () => {
    setIsSaving(true);
    setIsNotSaving(false)
    if (recordedVideo) {
      const formData = new FormData();
      formData.append("File", recordedVideo);
      let payload = {
        SaveVideo: false,
        UniqueAppointmentId: dataList?.UniqueAppointmentId,
      };
     
      dispatch(
        createSessionAction(payload, formData, () => {
          setModalOpen(false);
          closeModal()
          dispatch(getAppointmentAction(DoctorId));
         
          dispatch(getDocsAction(DoctorId));
        })
      );
    }
  };

  return (
    <>
      <Modal isOpen={true} centered size="xl">
        <Row className="d-flex justify-content-between align-items-center pt-3 px-4">
          <Col className="flex-grow-1">
            <span>Session Start</span>
          </Col>
          <Col xs="auto" className="text-end">
            <button onClick={closeModal} type="button" class="close">
              <span aria-hidden="true">&times;</span>
            </button>
          </Col>
        </Row>
        <ModalBody>
          <div className="my-3 border" style={{ height: 500 }}>
            <VideoRecorder
              onRecordingComplete={handleRecordingComplete}
              onError={(e) => {
                console.log("Error in recorder", e);
              }}
              isFlipped={true}
              isOnInitially={true}
              countdownTime={getSetting?.DelayTimer * 1000}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          {/* <Button disabled={loading} color="danger" onClick={closeModal}>
            Close
          </Button> */}
          {/* <Button
            type="submit"
            color="primary"
            disabled={loading}
            onClick={submit}
          >
            Save {loading && <ImSpinner8 className="spinning-icon" />}
          </Button> */}
        </ModalFooter>
      </Modal>
      {modalOpen && <SaveSessionModal submit={SaveVideo} NotSavedVideo={NotSavedVideo} isSaving={isSaving} isNotSaving={isNotSaving}/>}
    </>
  );
};

export default SessionStartModal;
