import { SETTING_LOADER, GET_SETTING } from "../types";

const initialState = {
  loading: false,
  getSetting: [],
};
//getSetting and loading are the states in the redux store.

export default function settingReducer(state = initialState, action) {
  switch (action?.type) {
    case SETTING_LOADER:
      return {
        ...state, 
        loading: action?.payload,
      };
    case GET_SETTING:
      return {
        ...state,
        getSetting: action?.payload,
      };
    default:
      return { ...state };
  }
}
