import React from "react";
import {
  Col,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Button,
  Row,
  Container,
} from "reactstrap";
import { ImSpinner8, ImEnvelop } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { UpdatePasswordAction } from "store/actions/AuthAction";
import { useFormik } from "formik";
import { PasswordUpdateSchema } from "components/validation/UpdatePasswordValidation";
import { IoIosEye } from "react-icons/io";
import { IoIosEyeOff } from "react-icons/io";
import OnlyHeader from "components/headers/OnlyHeader.js";
import "../../assets/scss/PasswordUpdate.scss"
import { GoDotFill } from "react-icons/go";

const PasswordUpdate = () => {
  //Getting the user email to be displayed in the form
  const { user } = useSelector((state) => state.authUser);
  const DoctorEmail = user?.DoctorEmail;

  const loader = useSelector((state) => state.userData?.loading);
  const userEmail = useSelector((state) => state?.authUser?.user?.DoctorEmail);
  const dispatch = useDispatch();
  const history = useHistory();
  const [showpass, setShowPass] = React.useState("password");
  const [showConfrmpass, setShowConfrmpass] = React.useState("password");

  const formik = useFormik({
    initialValues: {
      OldPassword: "",
      NewPassword: "",
    },
    validationSchema: PasswordUpdateSchema,
    onSubmit: (values) => {
      let payload = {
        Email: userEmail,
        OldPassword: values.OldPassword,
        NewPassword: values.NewPassword,
      };

      dispatch(
        UpdatePasswordAction(payload, () => {
          history.push("/auth/login");
        })
      );
    },
  });

  const showHidePass = (e) => {
    e.preventDefault();
    setShowPass((prev) => (prev === "password" ? "text" : "password"));
  };

  const ConfirmPass = (e) => {
    e.preventDefault();
    setShowConfrmpass((prev) => (prev === "password" ? "text" : "password"));
  };

  return (
    <>
      <OnlyHeader />
      <Container className="mt--7 " fluid>
        <Row className="d-flex justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary shadow border-0 flex justify-center">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <small>Update Password</small>
                </div>
                <Form onSubmit={formik.handleSubmit} role="form">
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <ImEnvelop  />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        //having a value attribute already makes it muted that's why I didn't add disabled attribute
                        // defaultValue={DoctorEmail}
                        // disabled
                        value={DoctorEmail}
                        id="email"
                        readOnly
                        
                 
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Old Password"
                        name="OldPassword"
                        value={formik.values.OldPassword}
                        onChange={formik.handleChange}
                        type={showpass}
                      />
                      <a
                        href="showHidePass"
                        className="password-ShowIcon pt-2 px-2"
                        onClick={showHidePass}
                      >
                        {showpass === "password" ? (
                          <IoIosEyeOff />
                        ) : (
                          <IoIosEye />
                        )}
                      </a>
                    </InputGroup>
                    {formik.touched.OldPassword &&
                      formik.errors.OldPassword && (
                        <small className="text-danger">
                          <GoDotFill size={20} />
                          {formik.errors.OldPassword}
                        </small>
                      )}
                  </FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="New Password"
                      type={showConfrmpass}
                      name="NewPassword"
                      value={formik.values.NewPassword}
                      onChange={formik.handleChange}
                    />
                    <a
                      href="showHidePass"
                      className="password-ShowIcon pt-2 px-2"
                      onClick={ConfirmPass}
                    >
                      {showConfrmpass === "password" ? (
                        <IoIosEyeOff />
                      ) : (
                        <IoIosEye />
                      )}
                    </a>
                  </InputGroup>
                  {formik.touched.NewPassword && formik.errors.NewPassword && (
                    <small className="text-danger">
                      <GoDotFill size={20} />
                      {formik.errors.NewPassword}
                    </small>
                  )}
                  <div className="text-center">
                    <Button className="my-4" color="primary" type="submit">
                      Update Password{" "}
                      {loader && <ImSpinner8 className="spinning-icon" />}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PasswordUpdate;
