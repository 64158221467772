import React from "react";
import {
  Col,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Button,
} from "reactstrap";
import { ImSpinner8 } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { VerifyAction } from "store/actions/AuthAction";
import { GoDotFill } from "react-icons/go";
import { useFormik } from "formik";
import { OtpVerifySchema } from "components/validation/OtpValidation";

const OtpVerify = () => {
  const loader = useSelector((state) => state.userData.loading);
  const userEmail = useSelector((state) => state?.authUser?.email);
  const dispatch = useDispatch();
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      Otp: "",
      Email: userEmail,
    },
    validationSchema: OtpVerifySchema,
    onSubmit: (values) => {
      dispatch(
        VerifyAction(values, () => {
          history.push("/auth/login");
        })
      );
    },
  });

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Verification</small>
            </div>
            <Form onSubmit={formik.handleSubmit} role="form">
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="OTP"
                    name="Otp"
                    value={formik.values?.Otp}
                    onChange={formik.handleChange}
                    type="text"
                    // required
                  />
                </InputGroup>
                {formik.touched.Otp && formik.errors.Otp && (
                  <small className="text-danger"><GoDotFill size={20}/>  {formik.errors.Otp}</small>
                )}
              </FormGroup>
              <div className="text-center">
                <Button type="submit" className="my-4" color="primary">
                  Confirm {loader && <ImSpinner8 className="spinning-icon" />}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default OtpVerify;
