import { ADD_PATIENT, UPDATE_PATIENT, DELETE_PATIENT, GET_PATIENT,PATIENT_LOADER } from "store/types";
import { toast } from "react-toastify";
import axiosObj from "utils/axios";

export const setPatientLoading = (val) => async (dispatch) => {
    dispatch({ type: PATIENT_LOADER, payload: val });
};

// This function is for Add patient Data
export const addPatientAction = (payload, onSuccess) => async (dispatch) => {
    await dispatch(setPatientLoading(true));
    try {
        let { data } = await axiosObj.post(`/Patient/Add-Patient`, payload) 
        if (data?.status_code === 200) {
            toast.success("Patient Created Successfully");
            dispatch({ type: ADD_PATIENT, payload: data?.detail })
            onSuccess();
            await dispatch(setPatientLoading(false));
        } else {
            toast.warning(data?.detail);
            await dispatch(setPatientLoading(false));
        }
    } catch (e) {
        toast.error(e.message);
        await dispatch(setPatientLoading(false));
    }
}

// This function is for Edit patient given data
export const updatePatientAction = (Id, payload, onSuccess) => async (dispatch) => {
    console.log(Id,"its Id")
    await dispatch(setPatientLoading(true));
    try {
        // Directly using the correct URL string in the request
        let { data } = await axiosObj.put(`/Patient/Update-Patient?id=${Id}`, payload);
        if (data?.status_code === 200) {
            toast.success(data?.detail);
            dispatch({ type: UPDATE_PATIENT, payload: { Id, ...payload } });
            onSuccess();
            await dispatch(setPatientLoading(false));
        } else {
            toast.warning(data?.detail);
            await dispatch(setPatientLoading(false));
        }
    } catch (e) {
        toast.error(e.message);
        await dispatch(setPatientLoading(false));
    }
};


// this action is for delete patient
export const deletePatientAction = (patientId, onSuccess) => async (dispatch) => {
    await dispatch(setPatientLoading(true));
    try {
        let { data } = await axiosObj.delete(`/Patient/Del-Patient?id=${patientId}`);
        if (data?.status_code === 200) {
            toast.success("Patient delete Successfully");
            dispatch({ type: DELETE_PATIENT,payload:patientId })
            onSuccess();
            await dispatch(setPatientLoading(false));
        } else {
            toast.warning(data?.detail);
            await dispatch(setPatientLoading(false));
        }
    } catch (e) {
        console.error(e.message);
        await dispatch(setPatientLoading(false));
      
    }

}

// This action is for get patient
export const getPatientAction = (DoctorId) => async (dispatch) => {
    await dispatch(setPatientLoading(true));
    try {
        let { data } = await axiosObj.get(`/Patient/Get-All-Patients?DoctorId=${DoctorId}`);
        if (data?.status_code === 200) {
            dispatch({ type: GET_PATIENT,payload:data?.detail})
            await dispatch(setPatientLoading(false));
        } else {
            toast.warning(data?.detail);
            await dispatch(setPatientLoading(false));
        }
    } catch (e) {
        toast.error(e.message);
        await dispatch(setPatientLoading(false));
    }
}

