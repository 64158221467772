import { SESSION_LOADER, GET_SESSION, SESSION_CREATE } from "store/types";
import { toast } from "react-toastify";
import axiosObj from "utils/axios";

export const setSessionLoading = (val) => async (dispatch) => {
  dispatch({ type: SESSION_LOADER, payload: val });
};

// This function is for Create video Session
export const createSessionAction =
  (payload, file, onSuccess) => async (dispatch) => {
    await dispatch(setSessionLoading(true));
    try {
      let { data } = await axiosObj.post(
        `/Reports/Create-Session?SaveVideo=${payload?.SaveVideo}&UniqueAppointmentId=${payload?.UniqueAppointmentId} `,
        file
      );
      if (data?.status_code === 200) {
        if(payload?.SaveVideo){

          toast.success("Session successfully saved");
        }
        onSuccess();
        await dispatch({ type: SESSION_CREATE, payload: data?.detail });
        await dispatch(setSessionLoading(false));
      } else {
        toast.error(data?.detail);
        await dispatch(setSessionLoading(false));
      }
    } catch (e) {
      toast.error(e.message);
      await dispatch(setSessionLoading(false));
    }
  };

export const getSessionAction = (DocId) => async (dispatch) => {
  await dispatch(setSessionLoading(true));
  try {
    let { data } = await axiosObj.get(`/Reports/Get-Reports-By-DoctorId?DoctorId=${DocId}`);
    if (data?.status_code === 200) {
      await dispatch(setSessionLoading(false));
      await dispatch({ type: GET_SESSION, payload: data?.detail });
    } else {
      // toast.warning(data?.detail);
      await dispatch(setSessionLoading(false));
    }
  } catch (e) {
    console.error(e.message);
    await dispatch(setSessionLoading(false));
  }
};

// This function is for Update Video session
export const updateSession = (id, payload, onSuccess) => async (dispatch) => {
  await dispatch(setSessionLoading(true));
  try {
    let { data } = await axiosObj.put(`/Reports/Update-Report?Report_id=${id}`, payload);
    if (data?.status_code === 200) {
      toast.success(data?.detail);
      // dispatch({ type: UPDATE_PATIENT, payload:{id,...payload}})
      onSuccess();
      await dispatch(setSessionLoading(false));
    } else {
      toast.warning(data?.detail);
      await dispatch(setSessionLoading(false));
    }
  } catch (e) {
    toast.error(e.message);
    await dispatch(setSessionLoading(false));
    dispatch(setSessionLoading(false));
  }
};

export const deleteSessionAction = (id, onSuccess) => async (dispatch) => {
  await dispatch(setSessionLoading(true));
  try {
    let { data } = await axiosObj.delete(`/Reports/Delete-Report?ReportId=${id}`);
    if (data?.status_code == 200) {
      toast.success(data?.detail);
      onSuccess();
      await dispatch(setSessionLoading(false));
      // await dispatch({type:DELETE_SESSION,payload:id});
    } else {
      toast.warning(data?.detail);
      await dispatch(setSessionLoading(false));
    }
  } catch (e) {
    toast.error(e.message);
    await dispatch(setSessionLoading(false));
  }
};


