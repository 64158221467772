import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { LoginAction } from "store/actions/AuthAction";
import { ImSpinner8 } from "react-icons/im";
import { IoIosEye, IoIosEyeOff } from "react-icons/io";
import { GoDotFill } from "react-icons/go";
import { LoginSchema } from "components/validation/LoginValidation";

const Login = () => {
  const loader = useSelector((state) => state.userData.loading);
  const dispatch = useDispatch();
  const history = useHistory();
  const [showpass, setShowPass] = useState("password");

  const formik = useFormik({
    initialValues: {
      Email: "",
      Password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      dispatch(
        LoginAction(values, () => {
          history.push("/admin/patients");
        })
      );
    },
  });
  

  const showHidePass = (e) => {
    e.preventDefault();
    if (showpass === "password") {
      setShowPass("text");
    } else {
      setShowPass("password");
    }
  };
  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Sign in</small>
            </div>
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    name="Email"
                    value={formik.values.Email}
                    onChange={formik.handleChange}
                    type="email"
                  />
                </InputGroup>
                {formik.touched.Email && formik.errors.Email && (
                  <small className="text-danger">
                    {" "}
                    <GoDotFill size={20} /> {formik.errors.Email}
                  </small>
                )}
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    name="Password"
                    value={formik.values.Password}
                    onChange={formik.handleChange}
                    type={showpass}
                  />
                  <a
                    href="showHidePass"
                    className="password-ShowIcon pt-2 px-2"
                    onClick={showHidePass}
                  >
                    {showpass === "password" ? <IoIosEyeOff /> : <IoIosEye />}
                  </a>
                </InputGroup>
                {formik.touched.Password &&
                  formik.errors.Password && (
                    <small className="text-danger">
                      {" "}
                      <GoDotFill size={20} /> {formik.errors.Password}
                    </small>
                  )}
              </FormGroup>
              <div className="text-center">
                <Button
                  className="my-4"
                  color="primary"
                  onClick={formik.handleSubmit}
                  type="submit"
                >
                  Sign in {loader && <ImSpinner8 className="spinning-icon" />}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6"></Col>
          <Col className="text-right" xs="6">
            <Link to={"/auth/register"} className="text-light">
              <small>Create new account</small>
            </Link>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default Login;
