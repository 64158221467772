/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
// reactstrap components
import { Card, CardBody, Container, Row, Col, CardHeader } from "reactstrap";
import OnlyHeader from "components/headers/OnlyHeader";
import { Nav, NavItem } from "reactstrap";
import { withRouter } from "react-router-dom";
import SessionsRecordingComp from "components/session/SessionRecordingComp";
import ConductedSession from "components/session/ConductedSession";
import FilterComponent from "components/FilterComponent";
import { getAppointmentAction } from "store/actions/AppointmentAction";
import { useSelector, useDispatch } from "react-redux";
import { getDocsAction} from "store/actions/DocsAction";

const SessionTabs = () => {
  const dispatch = useDispatch();
  const { document } = useSelector((state) => state?.docs);

  const { getApointments, loading } = useSelector(
    (state) => state?.oppointment
  ); // getting all Appointments patient list for recording session

  const DoctorId = useSelector((state) => state.authUser?.user?.DoctorId); // getting doctorId for getting session on doctor id base
  const loader = useSelector((state) => state?.session?.loading); // using for disable the button when recorded video send to backend
  const [filteredData, setFilteredData] = useState(""); // using for filter to search items in appointed recording session component
  const [conductedSession, setConductedSession] = useState(""); //using for filter to search items in conducted session
  const [type, setType] = useState("Appointment"); // Using for ternary operator for sending the data to filter component
  // Patient Appointment for recording oppointed session started
  useEffect(() => {
    dispatch(getAppointmentAction(DoctorId));
  }, [DoctorId]);

  useEffect(() => {
    setFilteredData(getApointments);
  }, [getApointments]);
  // Patient Appointment Ended

  // Conducted Session started
  useEffect(() => {
    dispatch(getDocsAction(DoctorId));
  }, [DoctorId]);
  useEffect(() => {
    setConductedSession(document);
  }, [document]);
  // Conducted Session ended

  const [currentActiveTab, setCurrentActiveTab] = useState(1);
  const toggle = (tab, type) => {
    setType(type);
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };
  const gliderStyle = {
    transform: currentActiveTab === 1 ? 'translateX(0)' : 'translateX(100%)',
  };
  return (
    <>
      <OnlyHeader />
      <Container className="mt--7" fluid>
        <Card className="shadow">
          <CardHeader className="py-5">
            <Row>
              <Col lg={3} sm={3} xs={4}>
                {" "}
                <h3 className="mb-0">Sessions</h3>
              </Col>
              <Col lg={6} sm={6} xs={8}>
                <FilterComponent
                  setFilteredData={
                    type === "Appointment"
                      ? setFilteredData
                      : setConductedSession
                  }
                  data={type === "Appointment" ? getApointments : document}
                />
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Nav>
              <div className="d-flex tabs SessiontabBackground rounded p-1">
                <NavItem className="border-none rounded">
                  <input
                    type="radio"
                    id="radio-1"
                    name="tabs"
                    checked={currentActiveTab === 1}
                    onChange={() => toggle(1, "Appointment")}
                  />
                  <label
                    htmlFor="radio-1"
                    className={`tab pointerEvent ${currentActiveTab === 1? "active-tab":""}`}
                  >
                    Appointments
                  </label>
                </NavItem>
                <NavItem>
                  <input
                    type="radio"
                    id="radio-2"
                    name="tabs"
                    checked={currentActiveTab === 2}
                    onChange={() => toggle(2, "conductedSession")}
                  />
                  <label
                    htmlFor="radio-2"
                    className={`tab pointerEvent   ${currentActiveTab === 2? "active-tab":""}`}
                  >
                    Conducted Session
                  </label>
                </NavItem>
                <div className="glider" style={gliderStyle}></div>
              </div>
            </Nav>
            {currentActiveTab == "1" && (
             
            
                <SessionsRecordingComp loading={loading} filteredData={filteredData} />
            
            )}
            {currentActiveTab == "2" && (
         
                <ConductedSession
                  getSession={conductedSession}
                  loading={loader}
                />
           
            )}
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default withRouter(SessionTabs);
