import { LOGIN, SIGNUP, LOGOUT } from "../types";

const initialState = {
  user: null,
  uid: null,
  token: null,
  email: [],
};

/*
    Any action related to Profile will go here.
*/

export default function authUserReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case LOGIN:
      return {
        ...state,
        user: payload,
        token: payload.AccessToken,
        uid: payload.UserId,
      };
    case SIGNUP:
      return {
        ...state,
        email: payload,
      };
    case LOGOUT:
      localStorage.removeItem("auth");
      return {
        ...state,
        user: null,
        uid: null,
        token: null,
      };
    default:
      return { ...state };
  }
}
