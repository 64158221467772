import React from "react";

const NoDataFound = () => {
  return (
    <tr className="text-center" style={{ height: "45vh " }}>
      <td colSpan={12}>No Data Found</td>
    </tr>
  );
};

export default NoDataFound;
