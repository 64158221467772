import React from "react";
import { Button, Col, Modal, ModalBody, ModalFooter, Row } from "reactstrap";
import { ImSpinner8 } from "react-icons/im";

//Below Submit, NotSavedVideo, isSaving Function is comming from SessionStart Modal
const SaveSessionModal = ({ submit, NotSavedVideo, isSaving, isNotSaving }) => {

  return (
    <>
      <Modal isOpen={true} centered>
        <Row className="d-flex justify-content-between align-items-center pt-3 px-4">
          <Col className="flex-grow-1">
            <span>Session Alert</span>
          </Col>
          <Col xs="auto" className="text-end"></Col>
        </Row>
        <ModalBody>
          <h5 className="text-center ">
            Are you sure you want to save session?
          </h5>
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={isSaving ? isSaving : isNotSaving}
            color="danger"
            onClick={NotSavedVideo}
          >
            No {isSaving && <ImSpinner8 className="spinning-icon" />}
          </Button>
          <Button
            type="submit"
            color="primary"
            disabled={isNotSaving ? isNotSaving : isSaving}
            onClick={submit}
          >
            Yes 
            {isNotSaving && <ImSpinner8 className="spinning-icon" />}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default SaveSessionModal;
