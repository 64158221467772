export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const SIGNUP = "SIGNUP";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const AUTH_SET_LOADING = "AUTH_SET_LOADING";
export const AUTH_NOTIFICATION = "AUTH_NOTIFICATION";
export const LOADER = "LOADER";
export const PATIENT_LOADER = "PATIENT_LOADER";
export const ADD_PATIENT = "ADD_PATIENT";
export const UPDATE_PATIENT = "UPDATE_PATIENT";
export const DELETE_PATIENT = "DELETE_PATIENT";
export const GET_PATIENT = "GET_PATIENT";
export const GET_APPOINTMENTS = "GET_APPOINTMENTS";
export const GET_APPOINTMENTS_BY_ID = "GET_APPOINTMENTS_BY_ID";
export const DELETE_APPOINTMENTS = "DELETE_APPOINTMENTS";
export const SHOW_PATIENT = "SHOW_PATIENT";
export const GET_SETTING = "GET_SETTING";
export const SETTING_LOADER = "SETTING_LOADER";
export const SESSION_LOADER = "SESSION_LOADER";
export const SESSION_CREATE = "SESSION_CREATE";
export const GET_SESSION = "GET_SESSION";
export const DELETE_SESSION = "DELETE_SESSION";
export const GET_DOCS = "GET_DOCS";
export const DOCS_LOADER = "DOCS_LOADER";
export const DELETE_DOCS ="DELETE_DOCS";
