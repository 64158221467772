import {
  ADD_PATIENT,
  DELETE_PATIENT,
  GET_PATIENT,
  SHOW_PATIENT,
  UPDATE_PATIENT,
  PATIENT_LOADER,
} from "../types";

const initialState = {
  patients: [],
  loading: false,
};

export default function patientReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case ADD_PATIENT:
      return {
        ...state,
        loading: payload,
      };
    case UPDATE_PATIENT:
      return {
        ...state,
        patients: state.patients.map((patient) =>
          patient.Id === payload.Id
            ? {
                ...patient,
                PatientName: payload.PatientName,
                PhoneNo: payload.PhoneNo,
                Email: payload.Email,
              }
            : patient
        ),
      };

    case DELETE_PATIENT:
      return {
        ...state,
        patients: state.patients.filter((patient) => patient.Id !== payload),
      };
    case GET_PATIENT:
      return {
        ...state,
        patients: payload,
        loading:payload,
      };
    case PATIENT_LOADER:
      return {
        ...state,
        loading: payload,
      };
    case SHOW_PATIENT:
      return {
        ...state,
        loading: payload,
      };

    default:
      return { ...state };
  }
}
