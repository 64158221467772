import { SETTING_LOADER, GET_SETTING } from "store/types";
import { toast } from "react-toastify";
import axiosObj from "utils/axios";

export const setSettingLoading = (val) => async (dispatch) => {
  dispatch({ type: SETTING_LOADER, payload: val });
};

// Create Setting
export const createSettingAction = (payload, onSuccess) => async (dispatch) => {
  await dispatch(setSettingLoading(true));
  try {
    let { data } = await axiosObj.post(`/Setting/Create-Setting`, payload);
    if (data?.status_code === 200) {
      toast.success(data?.detail);
      onSuccess();
      await dispatch(setSettingLoading(false));
    } else {
      toast.warning(data?.detail);
      await dispatch(setSettingLoading(false));
    }
  } catch (e) {
    toast.error(e.message);
    await dispatch(setSettingLoading(false));
  }
};

//Update Setting
export const updateSettingAction = (payload, onSuccess) => async (dispatch) => {
  await dispatch(setSettingLoading(true));
  try {
    let { data } = await axiosObj.put(`/Setting/Update-Setting`, payload);
    
    if (data?.status_code === 200) {
      toast.success(data?.detail);
      onSuccess();
      await dispatch(setSettingLoading(false));
    } else {
      toast.warning(data?.detail);
      await dispatch(setSettingLoading(false));
    }
  } catch (e) {
    toast.error(e.message);
    await dispatch(setSettingLoading(false));
  }
};

//Get Setting
export const getSettingAction = (id) => async (dispatch) => {
  await dispatch(setSettingLoading(true));
  try {
    let { data } = await axiosObj.get(`/Setting/Get-Setting-By-DoctorId?DoctorID=${id}`);
    if (data?.status_code === 200) {

      dispatch({ type: GET_SETTING, payload: data?.detail });
      // toast.success(data?.detail);
      await dispatch(setSettingLoading(false));
    } else {
      toast.warning(data?.detail);
      await dispatch(setSettingLoading(false));
    }
  } catch (e) {
    toast.error(e.message);
    await dispatch(setSettingLoading(false));
  }
};
