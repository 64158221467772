import { LOADER, LOGIN, LOGOUT, SIGNUP } from "../types";
import { toast } from "react-toastify";
import axiosObj from "utils/axios";

export const setAuthLoading = (val) => async (dispatch) => {
  dispatch({ type: LOADER, payload: val });
};

export const SignUpAction = (payload, onSuccess) => async (dispatch) => {
  await dispatch(setAuthLoading(true));
  try {
    let { data } = await axiosObj.post(`/Doctor/Create-Doc-Temp`, payload);
    if (data?.status_code === 200) {
      dispatch({ type: SIGNUP, payload: payload?.Email });
      toast.success("Successfully Sign Up");
      onSuccess();
      await dispatch(setAuthLoading(false));
    } else {
      toast.warning(data.detail);
      await dispatch(setAuthLoading(false));
    }
  } catch (e) {
    toast.error(e.message);
    await dispatch(setAuthLoading(false));
    dispatch(setAuthLoading(false));
  }
};
export const LoginAction = (payload, onSuccess) => async (dispatch) => {
  await dispatch(setAuthLoading(true));
  try {
    let { data } = await axiosObj.post(`/Doctor/LogIn`, payload);
    // let { data } = await auth.loginrep(payload);
    if (data?.status_code === 200) {
      dispatch({ type: LOGIN, payload: data?.detail });
      toast.success("Successfully LogIn");
      onSuccess();
      await dispatch(setAuthLoading(false));
    } else {
      toast.warning(data?.detail);
      await dispatch(setAuthLoading(false));
    }
  } catch (e) {
    toast.error(e);
    await dispatch(setAuthLoading(false));
  }
};

//Update password

export const UpdatePasswordAction =
  (payload, onSuccess) => async (dispatch) => {
    await dispatch(setAuthLoading(true));
    try {
      let { data } = await axiosObj.put(`/Doctor/Update-Password`, payload);
      if (data?.status_code === 200) {
        onSuccess();
        toast.success(data?.detail);
        await dispatch(setAuthLoading(false));
      } else {
        toast.error(data?.detail);
        await dispatch(setAuthLoading(false));
      }
    } catch (e) {
      toast.error(e);
      await dispatch(setAuthLoading(false));
    }
  };

  export const VerifyAction = (payload, onSuccess) => async (dispatch) => {
    await dispatch(setAuthLoading(true));
    try {
      // Using the payload directly as the body of the POST request
      let { data } = await axiosObj.post(`/Doctor/Verify-Doc`, payload);
      await dispatch(setAuthLoading(false));
      if (data?.status_code === 200) {
        toast.success(data.detail);
        // dispatch({ type: LOGIN, payload: data });
        onSuccess();
      } else {
        toast.error(data?.detail);
        await dispatch(setAuthLoading(false));
      }
    } catch (e) {
      toast.error(e.message);
      await dispatch(setAuthLoading(false));
    }
  };
  

export const logout = (onSuccess) => async (dispatch) => {
  localStorage.clear();
  onSuccess();
  dispatch({
    type: LOGOUT,
  });
};
