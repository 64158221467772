import React from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import { baseDomain } from "utils/axios";
const ConductedSession = ({ getSession }) => {
  console.log("getSession",getSession)
  return (
    <>
      {getSession?.length > 0 ? (
        <Row className="mt-4">
          {getSession &&
            getSession?.map(
              (item) =>
                item.RecordedVideoPath !== null && (
                  <Col key={item} lg={4} className="mt-2">
                    <Card>
                      <a
                        href={`${baseDomain}get_videofiles?ReportId=${item.ReportId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={`${baseDomain}get_thumbnails?ReportId=${item.ReportId}`}
                          //  src={item?.Thumbnail}
                          width="100%"
                          height="auto"
                          alt="..."
                        />
                      </a>
                      <CardBody>
                        <Row>
                          <Col lg={4}>
                            <span>Name</span>
                          </Col>
                          <Col lg={8}>
                            <span>{item?.PatientName}</span>
                          </Col>
                          <Col lg={4}>
                            <span>Disease</span>
                          </Col>
                          <Col lg={8}>
                            <span>{item?.PatientDisease}</span>
                          </Col>
                          <Col lg={4}>
                            <span>Date</span>
                          </Col>
                          <Col lg={8}>
                            <span>{item?.AppointmentDate}</span>
                          </Col>
                        </Row>

                        {/* <div className="">
                      <Button
                        className="btn-block btn-sm btn-danger"
                        type="button"
                        onClick={(e) => deleteHandler(e, item?.Id)}
                      >
                        Delete Session
                      </Button>
                    </div> */}
                      </CardBody>
                    </Card>
                  </Col>
                )
            )}
        </Row>
      ) : (
        <Row>
          <Col
            className="d-flex align-items-center justify-content-center"
            style={{ height: "50vh " }}
          >
            {/* <Spinner color="success" className="mx-1 "/> */}
            <span>No Session Exist</span>
          </Col>
        </Row>
      )}
    </>
  );
};
export default ConductedSession;
