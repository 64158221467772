import { DOCS_LOADER, GET_DOCS,DELETE_PATIENT } from "../types";

const initialState = {
  loading: false,
  document: [],
};
export default function documentReducer(state = initialState, action) {
  switch (action?.type) {
    case DOCS_LOADER:
      return {
        ...state,
        loading: action?.payload,
      };
    case GET_DOCS:
      return {
        ...state,
        document: action?.payload,
      };
    case DELETE_PATIENT:
      return {
        ...state,
        document: state.document.filter((document)=>document?.File!==action?.payload)  ,
        loading: action?.payload,
      };
    default:
      return { ...state };
  }
}
