// Root Reducer

import { combineReducers } from "redux";
import authUserReducer from "store/reducers/AuthUser";
import authReducer from "store/reducers/AuthReducer";
import patientReducer from "store/reducers/PatientReducer";
import oppointmentReducer from "store/reducers/AppointmentReducer";
import settingReducer from "store/reducers/SettingReducer";
import sessionReducer from "store/reducers/SessionReducer";
import documentReducer from "store/reducers/DocsReducer";
export let rootReducer = combineReducers({
  authUser: authUserReducer,
  userData: authReducer,
  patientData: patientReducer,
  oppointment:oppointmentReducer,
  setting:settingReducer,
  session:sessionReducer,
  docs:documentReducer,
 
});

export default rootReducer;
