/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Col,
} from "reactstrap";
import Header from "components/headers/Header.js";
import { useSelector, useDispatch } from "react-redux";
import DeleteModal from "components/modals/DeleteModal";
import {
  getAppointmentAction,
  deleteAppointmentAction,
  updateAppointmentAction,
} from "store/actions/AppointmentAction";
import { useFormik } from "formik";
import { validationSchema } from "components/validation/AppointmentsValidation";
import { ImSpinner8 } from "react-icons/im";
import FilterComponent from "components/FilterComponent";
import TableSkelton from "components/skelton/TableSkelton";
import NoDataFound from "components/skelton/NoDataFound";

const Appointments = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => setModalOpen(!modalOpen);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const dispatch = useDispatch();
  const { getApointments, loading } = useSelector((state) => state.oppointment);
  const [filteredData, setFilteredData] = useState("");

  const [id, setId] = useState("");
  const DoctorId = useSelector((state) => state.authUser?.user?.DoctorId);
  useEffect(() => {
    dispatch(getAppointmentAction(DoctorId));
  }, [DoctorId]);

  useEffect(() => {
    setFilteredData(getApointments);
  }, [getApointments]);

  const formik = useFormik({
    initialValues: {
      DoctorId: "",
      PatientId: "",
      Disease: "",
      Date: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        updateAppointmentAction(id, values, () => {
          dispatch(getAppointmentAction(DoctorId));
          toggleModal();
        })
      );
    },
  });

  const updateHandler = (e, item) => {
    e.preventDefault();
    setId(item.UniqueAppointmentId);
    toggleModal();
    formik.setValues({
      DoctorId: JSON.stringify(item.DoctorId),
      PatientId: JSON.stringify(item.PatientId),
      Disease: item.Disease,
      Date: item.Date,
    });
  };

  const handleDelete = (e, id) => {
    e.preventDefault();
    setId(id);
    setDeleteOpen(true);
  };

  const confirmDelete = (e) => {
    e.preventDefault();
    dispatch(
      deleteAppointmentAction(id, () => {
        // dispatch(getAppointmentAction());
        setDeleteOpen(false);
      })
    );
  };

  const CloseModal = () => {
    setDeleteOpen(false);
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Card className="shadow">
          <CardHeader className="py-5">
            <Row>
              <Col lg={3} sm={5} xs={12}>
                {" "}
                <h3 className="mb-0">Appointments</h3>
              </Col>
              <Col lg={6} sm={7} xs={12}>
                {" "}
                <FilterComponent
                  setFilteredData={setFilteredData}
                  data={getApointments}
                />
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">Patient Name</th>
                <th scope="col">Disease</th>
                <th scope="col">Date</th>
                <th scope="col" className="text-center">
                  Actions
                </th>
              </tr>
            </thead>

            <tbody>
              {filteredData?.length > 0 ? (
                filteredData?.slice() // Create a shallow copy of the array to avoid mutating the original array
                .sort((a, b) => 
                    b.Id-a.Id
                )?.map((appointment) => (
                  <tr key={appointment.Id}>
                    <td>{appointment?.PatientName}</td>
                    <td>{appointment?.Disease}</td>
                    <td>
                      <td>{appointment?.Date}</td>
                    </td>
                    <td className="text-right">
                      <Button
                        color="info"
                        onClick={(e) => {
                          updateHandler(e, appointment);
                        }}
                      >
                        Edit
                      </Button>
                      <Button
                        color="danger"
                        onClick={(e) => {
                          handleDelete(e, appointment?.UniqueAppointmentId);
                        }}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))
              ) : loading ? (
                <TableSkelton Rows={4}/>
              ) : (
                <NoDataFound />
              )}
            </tbody>
          </Table>
        </Card>
      </Container>

      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Edit Appointment</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Input
              type="text"
              name="Disease"
              value={formik.values.Disease}
              onChange={formik.handleChange}
              placeholder="Disease"
            />
            {formik.errors.Disease && (
              <small className="text-danger">{formik.errors.Disease}</small>
            )}
          </FormGroup>
          <FormGroup>
            <Input
              type="date"
              name="Date"
              value={formik.values.Date}
              onChange={formik.handleChange}
              min={new Date().toISOString().split("T")[0]}
              id="date"
            />
            {formik.errors.Date && (
              <small className="text-danger">{formik.errors.Date}</small>
            )}
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            disabled={loading}
            onClick={formik.handleSubmit}
          >
            Save Changes {loading && <ImSpinner8 className="spinning-icon" />}
          </Button>
          <Button color="danger" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      {deleteOpen && (
        <DeleteModal
          data={id}
          DeleteHandler={confirmDelete}
          loading={loading}
          closeModal={CloseModal}
        />
      )}
    </>
  );
};

export default Appointments;
