// chrome.exe --user-data-dir="C://Chrome dev session" --disable-web-security
import axios from "axios";
 export const baseDomain = "https://backend.aiscripts.ai/";
// export const baseDomain = "http://192.168.18.132:8080/";
// export const baseDomain = "http://127.0.0.1:8080";
//export const baseDomain ="http://192.168.18.31:8080/"
const baseURL = `${baseDomain}`;




export const axiosObj = axios.create({
  baseURL,
});
axiosObj.interceptors.request.use(
  (config) => {
    // Dynamically set the token for each request
    const ls = JSON.parse(localStorage.getItem("authUser"));
    const token = ls?.token || "";
    config.headers.token = token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default axiosObj;
