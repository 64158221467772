import {useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import {
  Button,
  Card,
  Col,
  Row,
  Spinner,
  Table,
} from "reactstrap";

import SessionStartModal from "components/modals/SessionStartModal";
const SessionsRecordingComp = ({loading,filteredData}) => {
  console.log("filteredData___",filteredData)

  
  const [sessionOpen, setSessionOpen] = useState(false);
  const [appointData, setAppointData] = useState();

  const startSessionHandler = (e, patient) => {
    e.preventDefault();
    setSessionOpen(true);
    setAppointData(patient);
  };
  const CloseModal = () => {
    setSessionOpen(false);
  };
  return (
    <>
      <Row className={`mt-4`}>
        <Col lg={12}>
          <Card className="shadow">
            {/* Patients table */}
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Patient Name</th>
                    <th scope="col">Diagnose</th>
                    <th scope="col">Date</th>
                    <th scope="col" className="text-center">
                      Action
                    </th>
                  </tr>
                </thead>

                <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="6" className="text-center" style={{height:"50vh "}}>
                      <Spinner color="success" className="mx-1 "/>
                    </td>
                  </tr>
                ) :
                  filteredData?.length > 0 ? (
                    filteredData?.map((appointment) => (
                      <tr key={appointment.Id}>
                        <td>{appointment?.PatientName}</td>
                        <td>{appointment?.Disease}</td>
                        <td>
                          <td>{appointment?.Date}</td>
                        </td>
                        <td className="text-center">
                          <Button
                            color="info"
                            onClick={(e) => startSessionHandler(e, appointment)}
                          >
                            Start Session
                          </Button>
                         
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="text-center" style={{height:"50vh "}}>
                      <td colSpan={12}>No appointments found</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            
          </Card>
        </Col>
      </Row>

      {/* Start Session Modal */}
      {sessionOpen && (
        <SessionStartModal dataList={appointData} closeModal={CloseModal} loading={false}/>
      )}

    </>
  );
};

export default SessionsRecordingComp;
