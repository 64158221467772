/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import {
  Badge,
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  Button,
  Spinner,
} from "reactstrap";
import Header from "components/headers/Header.js";
import FilterComponent from "components/FilterComponent";
import { useEffect } from "react";
import DeleteModal from "components/modals/DeleteModal";
import { getDocsAction } from "store/actions/DocsAction";
import { deleteSessionAction } from "store/actions/SessionAction";
import { useSelector, useDispatch } from "react-redux";
import { baseDomain } from "utils/axios";
import TableSkelton from "components/skelton/TableSkelton";
import NoDataFound from "components/skelton/NoDataFound";
const Docs = () => {
  const dispatch = useDispatch();
  const { document, loading } = useSelector((state) => state?.docs);
  const DoctorId = useSelector((state) => state.authUser?.user?.DoctorId);
  const [openModal, setOpenModal] = useState(false);
  const [Id, setId] = useState();
  const [filteredData, setFilteredData] = useState("");

  useEffect(() => {
    dispatch(getDocsAction(DoctorId));
  }, [DoctorId]);
  useEffect(() => {
    setFilteredData(document);
  }, [document]);

  const deleteHandler = (e, id) => {
    e.preventDefault();
    setId(id);
    setOpenModal(true);
  };

  const confirmDelete = () => {
    dispatch(
      deleteSessionAction(Id, () => {
        dispatch(getDocsAction(DoctorId));
        setOpenModal(false);
      })
    );
  };

  const CloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Card className="shadow">
          <CardHeader className="py-5">
            <Row>
              <Col lg={3} sm={3} xs={4}>
                {" "}
                <h3 className="mb-0">Docs</h3>
              </Col>
              <Col lg={6} sm={6} xs={8}>
                {" "}
                <FilterComponent
                  setFilteredData={setFilteredData}
                  data={document}
                />
              </Col>
              <Col lg={3} sm={3} xs={12}></Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">Patient Name</th>
                <th scope="col">Email</th>
                <th scope="col">Date</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredData?.length > 0 ? (
                filteredData?.map(
                  (item) =>
                    item.RecordedVideoPath !== null && (
                      <tr key={item.id}>
                        <td>{item?.PatientName}</td>
                        <td>
                          <Badge color="" className="badge-dot mr-4">
                            <i className="bg-success" />
                            {item?.PatientEmail}
                          </Badge>
                        </td>
                        <td>
                          <div className="avatar-group">
                            <li>{item.AppointmentDate}</li>
                          </div>
                        </td>
                        <td className="text-right">
                          <Button
                            color="danger"
                            onClick={(e) => deleteHandler(e, item?.ReportId)}
                          >
                            Delete
                          </Button>
                          <a
                            href={`${baseDomain}get_reportFiles?ReportId=${item?.ReportId}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Button type="button">View File</Button>
                          </a>
                        </td>
                      </tr>
                    )
                )
              ) : loading ? (
                <TableSkelton Rows={4} />
              ) : (
                <NoDataFound />
              )}
            </tbody>
          </Table>
        </Card>
      </Container>
      {openModal && (
        <DeleteModal
          data={Id}
          DeleteHandler={confirmDelete}
          loading={loading}
          closeModal={CloseModal}
        />
      )}
    </>
  );
};

export default Docs;
