import { Link } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import SettingsModal from "components/modals/SettingsModal";

const AdminNavbar = (props) => {
  const history = useHistory();
  const LogoutHandler = (e) => {
    e.preventDefault();
    localStorage.removeItem("authUser");
    history.push("/auth/login");
  };

  //state for managing the settings modal.
  const [settingsModal, setSettingsModal] = useState(false);
  const toggleSettingsModal = () => setSettingsModal(!settingsModal);

  //dynamic name for the user
  const { user } = useSelector((state) => state.authUser);
  const DoctorName = user!==null && user.DoctorName;
  const DoctorEmail = user!==null && user.DoctorEmail;

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <Link
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            to="/"
          >
            {props.brandText}
          </Link>
          <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto"></Form>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      src={
                        require("../../assets/img/theme/team-4-800x800.jpg")
                          .default
                      }
                    />
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {DoctorName}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem>
                <DropdownItem className="" header tag="div">
                <h6 className="text-overflow m-0">{DoctorEmail}</h6>
              </DropdownItem>
              <DropdownItem divider />
                <DropdownItem to="/admin/user-profile">
                  <i className="ni ni-single-02" />
                  <Link
                    style={{ color: "inherit", textDecoration: "none" }}
                    to={"/admin/password"}
                  >
                    Update Password
                  </Link>
                </DropdownItem>
                {/* Ateeba added this  */}
                <DropdownItem onClick={()=>{
                  toggleSettingsModal();
                }}>
                <i className="fas fa-cog" />
                  <span>Settings</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem href="#pablo" onClick={LogoutHandler}>
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>

      {/* Settings Modal */}
      {settingsModal && <SettingsModal     
        toggleSettingsModal={toggleSettingsModal}
        settingsModal={settingsModal}
      />}
    </>
  );
};

export default AdminNavbar;
