import { AUTH_NOTIFICATION, AUTH_SET_LOADING, LOADER } from "../types";

const initialState = {
    notification: {
        message: "",
        type: "",
    },
    loading: false,
};

export default function AuthReducer(state = initialState, { type, payload }) {
    switch (type) {
        case AUTH_NOTIFICATION:
            return {
                ...state,
                notification: payload,
            };
        case AUTH_SET_LOADING:
            return {
                ...state,
            };
        case LOADER:
            return {
                ...state,
                loading: payload,
            };

        default:
            return {
                ...state,
            };
    }
}
