import { LOADER, GET_APPOINTMENTS, DELETE_APPOINTMENTS,GET_APPOINTMENTS_BY_ID } from "../types";

const initialState = {
  loading: false,
  getApointments: [],
  AppointedById:[]
};

export default function oppointmentReducer(state = initialState, { type, payload }) {
  switch (type) {
    case LOADER:
      return {
        ...state,
        loading: payload,
      };
    case GET_APPOINTMENTS:
      return {
        ...state,
        getApointments:payload,
      };
    case GET_APPOINTMENTS_BY_ID:
      return {
        ...state,
        AppointedById:payload,
      };
    case DELETE_APPOINTMENTS:
      return {
        ...state,
        getApointments: state.getApointments.filter(
          (appointment) => appointment?.UniqueAppointmentId!== payload
        ),
      };
    default:
      return { ...state };
  }
}
