/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { HiOutlineSearch } from "react-icons/hi";

const FilterComponent = ({ setFilteredData, data }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    const search = e.target.value.toLowerCase();
    setSearchTerm(search);
    if (!search) {
      setFilteredData(data);
      return;
    }

    const filteredArray = data.filter(
      (item) =>
      item.PhoneNo &&
        item.PhoneNo.toLowerCase().includes(search) ||
        item.Email &&
        item.Email.toLowerCase().includes(search) ||
        item.PatientName &&
        item.PatientName.toLowerCase().includes(search)
    );

    setFilteredData(filteredArray);
  };
  return (
    <div className="d-flex outer-input-div align-items-center border border-1 rounded px-1   ">
      <HiOutlineSearch size={20} className="ml-2" />
      <input
        type="text"
        placeholder="Email,name,phone..."
        value={searchTerm}
        className="form-control input-field mx-2 rounded-5 border-0 py-0"
        onChange={handleSearch}
      />
    </div>
  );
};

export default FilterComponent;
