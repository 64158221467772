import { LOADER, GET_APPOINTMENTS,DELETE_APPOINTMENTS,GET_APPOINTMENTS_BY_ID } from "store/types";
import { toast } from "react-toastify";
import axiosObj from "utils/axios";

export const setOppointmentLoading = (val) => async (dispatch) => {
  dispatch({ type: LOADER, payload: val });
};

// Create Appointement
export const createAppointmentAction =
  (payload, onSuccess) => async (dispatch) => {
    await dispatch(setOppointmentLoading(true));
    dispatch({ type: LOADER, payload: true });
    try {
      let { data } = await axiosObj.post(`/Appointment/Create-Appointment`, payload);
      
      if (data?.status_code === 200) {
        toast.success(data?.detail?.Message
          );
        onSuccess();
        await dispatch(setOppointmentLoading(false));
      } else {
        toast.warning(data?.detail);
        await dispatch(setOppointmentLoading(false));
      }
    } catch (e) {
      toast.error(e.message);
      await dispatch(setOppointmentLoading(false));
    }
  };
export const getAppointmentAction =
  (id, onSuccess) => async (dispatch) => {
    await dispatch(setOppointmentLoading(true));
    try {
      let { data } = await axiosObj.get(`/Appointment/Get-All-Appointments?DocId=${id}`);
      if (data?.status_code === 200) {
        // onSuccess();
        dispatch({ type: GET_APPOINTMENTS, payload: data.detail });
        await dispatch(setOppointmentLoading(false));
      } else {
        toast.warning(data?.detail);
        await dispatch(setOppointmentLoading(false));
      }
    } catch (e) {
      toast.error(e.message);
      await dispatch(setOppointmentLoading(false));
    }
  };

//Update Appointement
export const updateAppointmentAction =
  (id, payload, onSuccess) => async (dispatch) => {
    await dispatch(setOppointmentLoading(true));
    try {
      let { data } = await axiosObj.put(`/Appointment/Update-Appointment?uid=${id}`, payload);
      if (data?.status_code === 200) {
        toast.success(data?.detail);
        onSuccess();
        await dispatch(setOppointmentLoading(false));
      } else {
        toast.warning(data?.detail);
        await dispatch(setOppointmentLoading(false));
      }
    } catch (e) {
      toast.error(e.message);
      await dispatch(setOppointmentLoading(false));
    }
  };

//Delete Appointement
export const deleteAppointmentAction = (id, onSuccess) => async (dispatch) => {
  await dispatch(setOppointmentLoading(true));
  try {
    let { data } = await axiosObj.delete(`/AppointmentDelete-Appointment?uid=${id}`);
    if (data?.status_code === 200) {
      toast.success(data?.detail);
      onSuccess();
      await dispatch({type:DELETE_APPOINTMENTS,payload:id});
      await dispatch(setOppointmentLoading(false));
    } else {
      toast.warning(data?.detail);
      await dispatch(setOppointmentLoading(false));
    }
  } catch (e) {
    toast.error(e.message);
    await dispatch(setOppointmentLoading(false));
  }
};

export const getAppointmentByIdAction =
  (Id, onSuccess) => async (dispatch) => {
    await dispatch(setOppointmentLoading(true));
    try {
      let { data } = await axiosObj.get(`/AppointmentDelete-Appointment?uid=${Id}`);
      if (data?.status_code === 200) {
        dispatch({ type: GET_APPOINTMENTS_BY_ID, payload: data.detail });
        await dispatch(setOppointmentLoading(false));
      } else {
        toast.warning(data?.detail);
        await dispatch(setOppointmentLoading(false));
      }
    } catch (e) {
      toast.error(e.message);
      await dispatch(setOppointmentLoading(false));
    }
  };