import { DOCS_LOADER, GET_DOCS,DELETE_DOCS } from "store/types";
import { toast } from "react-toastify";
import axiosObj from "utils/axios";

export const setDocsLoading = (val) => async (dispatch) => {
  dispatch({ type: DOCS_LOADER, payload: val });
};

// GET Docs
export const getDocsAction = (id) => async (dispatch) => {
  await dispatch(setDocsLoading(true));
  try {
    let { data } = await axiosObj.get(`/Reports/Get-Document?DocId=${id}`);
    if (data?.status_code == 200) {
      if (data?.detail.length > 0) {
        await dispatch(setDocsLoading(false));
        await dispatch({ type: GET_DOCS, payload: data?.detail });
      } else {
        await dispatch(setDocsLoading(false));
      
      }
    } else {
      await dispatch({ type: GET_DOCS, payload: [] });
      // toast.warning(data?.detail);
      await dispatch(setDocsLoading(false));
    }
  } catch (e) {
    toast.error(e.message);
    await dispatch(setDocsLoading(false));
  }
};

//Delete Docs
export const deleteDocsAction = (filePath, onSuccess) => async (dispatch) => {
  await dispatch(setDocsLoading(true));
  try {
    let { data } = await axiosObj.delete(`/Reports/Delete-Document?File=${filePath}`);
    if (data?.status_code === 200) {
      toast.success(data?.detail);
      onSuccess();
      await dispatch({type:DELETE_DOCS, payload:filePath});
      await dispatch(setDocsLoading(false));
    } else {
      toast.warning(data?.detail);
      await dispatch(setDocsLoading(false));
    }
  } catch (e) {
    toast.error(e.message);
    await dispatch(setDocsLoading(false));
  }
};
