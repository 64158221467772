import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import { createAppointmentAction } from "store/actions/AppointmentAction";
import { CreateAppointmentSchema } from "components/validation/CreateAppointmentValidation";
import { GoDotFill } from "react-icons/go";

const AppointmentModal = ({
  AppointmentedPerson,
  closeModal
}) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.oppointment);
  const { user } = useSelector((state) => state.authUser);

  const formik = useFormik({
    initialValues: {
      diagnose: "",
      date: "",
    },
    validationSchema: CreateAppointmentSchema,
    onSubmit: (values) => {
      let payload = {
        DoctorId: user?.DoctorId.toString(),
        PatientId: AppointmentedPerson.toString(),
        Date: values.date,
        Disease: values.diagnose,
      };
      dispatch(
        createAppointmentAction(payload, () => {
          closeModal()
          // document.getElementById("diagnose").value=""
          // document.getElementById("date").value=""
        })
      );
      // dispatch(createAppointmentAction(values));
    },
  });

  return (
    <Modal isOpen={true} >
      <ModalHeader >Appointments</ModalHeader>
      <ModalBody>
        <form onSubmit={formik.handleSubmit}>
          <FormGroup>
            <Label for="diagnose">Diagnose</Label>
            <Input
              type="text"
              id="diagnose"
              name="diagnose"
              placeholder="Enter Diagnose"
              onChange={formik.handleChange}
              value={formik.values.diagnose}
            />
            {formik.touched.diagnose && formik.errors.diagnose && (
              <small className="text-danger  d-flex">
                {" "}
                <GoDotFill size={20} />
                {formik.errors.diagnose}
              </small>
            )}
            {/* <div className="d-flex justify-content-end mt-1">
              <span className="mt-auto">
                <button
                  className="btn btn-sm btn-info"
                  onClick={onChangeHandler}
                >
                  change
                </button>
              </span>
            </div> */}
          </FormGroup>

          <FormGroup>
            <Label for="date">Date</Label>
            <Input
              type="date"
              id="date"
              name="date"
              min={new Date().toISOString().split("T")[0]}
              onChange={formik.handleChange}
              value={formik.values.date}
            />
            {formik.touched.date && formik.errors.date && (
              <small className="text-danger d-flex">
                {" "}
                <GoDotFill size={20} />
                {formik.errors.date}
              </small>
            )}
          </FormGroup>

          <ModalFooter>
            <Button className="btn-width-full-sm" color="primary" type="submit">
              {loading ? <Spinner size="sm" /> : "Add Appointment"}
            </Button>
            <Button
              className="btn-width-full-sm"
              color="danger"
              onClick={closeModal}
            >
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default AppointmentModal;
