import React, { useState } from "react";
import {
  Col,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Button,
} from "reactstrap";
import { ImSpinner8 } from "react-icons/im";
import { IoIosEye, IoIosEyeOff } from "react-icons/io";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import  SignUpvalidation  from "components/validation/SignUpvalidation";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { SignUpAction } from "store/actions/AuthAction";
import { useHistory } from "react-router-dom";
import { GoDotFill } from "react-icons/go";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const Register = () => {
  const loader = useSelector((state) => state.userData.loading);
  const dispatch = useDispatch();
  const history = useHistory();
  const [showpass, setShowPass] = useState("password");

  const formik = useFormik({
    initialValues: {
      Name: "",
      Email: "",
      Specialization: "",
      PhoneNo: "",
      Password: "",
    },
    validationSchema: SignUpvalidation,
    onSubmit: (values) => {
      dispatch(
        SignUpAction(values, () => {
          history.push("/auth/verification");
        })
      );
    },
  });

  const showHidePass = (e) => {
    e.preventDefault();
    setShowPass((prev) => (prev === "password" ? "text" : "password"));
  };

  return (
    <>
      <Col lg="5" md="8">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Sign up</small>
            </div>
            <Form role="form" onSubmit={formik.handleSubmit}>
              <FormGroup>
                <InputGroup className="input-group-alternative ">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-hat-3" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Name"
                    name="Name"
                    value={formik.values.Name}
                    onChange={formik.handleChange}
                    type="text"
                  />
                </InputGroup>
                {formik.touched.Name && formik.errors.Name && (
                  <small className="text-danger"><GoDotFill size={20}/> {formik.errors.Name}</small>
                )}
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    name="Email"
                    value={formik.values.Email}
                    onChange={formik.handleChange}
                    type="email"
                  />
                </InputGroup>
                {formik.touched.Email && formik.errors.Email && (
                  <small className="text-danger"> <GoDotFill size={20}/> {formik.errors.Email}</small>
                )}
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-hat-3" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="Specialization"
                    value={formik.values.Specialization}
                    onChange={formik.handleChange}
                    placeholder="Specialization"
                    type="text"
                  />
                </InputGroup>
                {formik.touched.Specialization &&
                  formik.errors.Specialization && (
                    <small className="text-danger">
                      <GoDotFill size={20}/> 
                      {formik.errors.Specialization}
                    </small>
                  )}
              </FormGroup>
              <FormGroup>
                <PhoneInput
                  country={"us"} // Default country
                  placeholder="Enter phone number"
                  name="PhoneNo"
                  id="PhoneNo"
                  {...formik.getFieldProps("PhoneNo")}
                  value={formik.values.PhoneNo}
                  onChange={(value, data, event, formattedValue) => {
                    formik.setFieldValue("PhoneNo", formattedValue);
                  }}
                />
                {formik.touched.PhoneNo && formik.errors.PhoneNo && (
                  <small className="text-danger"> <GoDotFill size={20}/> {formik.errors.PhoneNo}</small>
                )}
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="Password"
                    value={formik.values.Password}
                    onChange={formik.handleChange}
                    placeholder="Password"
                    type={showpass}
                  />
                  <a
                    href="showHidePass"
                    className="password-ShowIcon pt-2 px-2"
                    onClick={showHidePass}
                  >
                    {showpass === "password" ? <IoIosEyeOff /> : <IoIosEye />}
                  </a>
                </InputGroup>
                {formik.touched.Password && formik.errors.Password && (
                  <small className="text-danger"><GoDotFill size={20}/> {formik.errors.Password}</small>
                )}
              </FormGroup>
              <div className="text-center">
                <Button
                  disabled={loader}
                  type="submit"
                  className="mt-4"
                  color="primary"
                >
                  Create account{" "}
                  {loader && <ImSpinner8 className="spinning-icon" />}
                </Button>
              </div>
              <div className="text-center mt-3">
                <span>Already have an account?</span>&nbsp;
                <Link
                 to={'/auth/login'}
                >
                   SignIn
                </Link>
              </div>

            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Register;
