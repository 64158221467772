/* eslint-disable react-hooks/exhaustive-deps */
import DeleteModal from "components/modals/DeleteModal";
import FilterComponent from "components/FilterComponent";
import Header from "components/headers/Header.js";
import { patientValidationSchema } from "components/validation/CreatePatientValidation";
import AppointmentModal from "components/modals/AppointmentModal";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { GoDotFill } from "react-icons/go";
import { ImSpinner8 } from "react-icons/im";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import {
  addPatientAction,
  deletePatientAction,
  getPatientAction,
  updatePatientAction,
} from "store/actions/PatientAction";
import TableSkelton from "components/skelton/TableSkelton";
import NoDataFound from "components/skelton/NoDataFound";

const Patients = () => {

  const DoctorId = useSelector((state) => state.authUser?.user?.DoctorId);
  const { patients, loading } = useSelector((state) => state.patientData);
  console.log(patients, "its ..");
  const dispatch = useDispatch();
  const [filteredData, setFilteredData] = useState("");

  /////session modal

  const [Add, setAdd] = useState("Add");
  const [modalOpen, setModalOpen] = useState(false);
  const [AppointmentedPerson, setAppointmentedPerson] = useState("");
  const [appointmentsModalOpen, setAppointmentsModalOpen] = useState(false);
  const [doctId, setDocId] = useState("");
  const [id,setId]=useState("")
  const [selectedPatient, setSelectedPatient] = useState("");
  const [AppointModal, setAppointModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  // These two functions are for Modals
  const toggleModal = () => setModalOpen(!modalOpen);

  useEffect(() => {
    dispatch(getPatientAction(DoctorId));
  }, []);

  const formik = useFormik({
    initialValues: {
      Id:"",
      DoctorId: DoctorId,
      PatientName: "",
      Email: "",
      PhoneNo: "",
    },
    validationSchema: patientValidationSchema,
    onSubmit: (values, { resetForm }) => {
      console.log("Submitting for PatientId:", values.Id); // Log to check the ID being submitted
      if (Add !== "Update") {
        dispatch(
          addPatientAction(values, () => {
            dispatch(getPatientAction(DoctorId)); // Assuming you need to fetch patients under a specific doctor
            toggleModal();
            resetForm();
          })
        );
      } else {
        console.log("Updating for PatientId:", values); // Confirm this changes
        dispatch(
          updatePatientAction(id, values, () => {
            setAdd("Add")
            setId("")
            dispatch(getPatientAction(DoctorId)); 
            setDocId("")
            resetForm();
            toggleModal();
          })
        );
      }
    },
  });
  const updateHandler = (e, patient) => {
    e.preventDefault();
    setDocId(patient.Id);
    setId(patient.Id)
    setAdd("Update");
    toggleModal();
    formik.setValues({
      DoctorId: DoctorId,
      PatientName: patient.PatientName,
      Email: patient.Email,
      PhoneNo: patient.PhoneNo,
    });
  };

  const deleteHandler = (e, patient) => {
    e.preventDefault();
    // setDocId(patient);
    setSelectedPatient(patient);
    setOpenModal(true);
  };

  const confirmDelete = () => {
    dispatch(
      deletePatientAction(selectedPatient.Id, () => {
        setOpenModal(false);
      })
    );
  };
  const CloseModal = () => {
    setOpenModal(false);
  };
  useEffect(() => {
    setFilteredData(patients);
  }, [patients]);
  const appointmentModalHandler = (e, id) => {
    e.preventDefault();
    setAppointmentedPerson(id);
    setAppointModal(true);
  };
  const closeModal = () => {
    setAppointModal(false);
  };
  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        {/* The overall card */}
        <Card className="shadow">
          {/* Card/Table header */}
          <CardHeader className="py-5">
            <Row>
              <Col lg={3} sm={3} xs={4}>
                {" "}
                <h3 className="mb-0">Patient</h3>
              </Col>
              <Col lg={6} sm={6} xs={8}>
                {" "}
                <FilterComponent
                  setFilteredData={setFilteredData}
                  data={patients}
                />
              </Col>
              <Col lg={3} sm={3} xs={12}>
                {" "}
                <Button
                  className="bg-primary text-white  mt-sm-0 mt-2  patient-responsive-btn"
                  onClick={toggleModal}
                >
                  Add Patient
                </Button>
              </Col>
            </Row>
          </CardHeader>
          {/* Ta */}
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light ">
              <tr>
                <th scope="col">SR</th>
                <th scope="col">Patient Name</th>
                <th scope="col">Email</th>
                <th scope="col">Phone No</th>
                <th scope="col" className="text-center">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredData?.length > 0 ? (
                filteredData?.slice() // Create a shallow copy of the array to avoid mutating the original array
                .sort((a, b) => 
                    b.Id-a.Id
                )?.map((patient, index) => (
                  <tr key={index}>
                    <th scope="row">{patient.Id}</th>
                    <td>{patient.PatientName}</td>
                    <td>
                      <Badge color="" className="badge-dot mr-4">
                        <i className="bg-success" />
                        {patient.Email}
                      </Badge>
                    </td>
                    <td>
                      <div className="avatar-group">
                        <li className="text-left">{patient.PhoneNo}</li>
                      </div>
                    </td>
                    <td className="text-right">
                      <Button
                        color="info"
                        onClick={(e) => updateHandler(e, patient)}
                      >
                        Edit
                      </Button>
                      <Button
                        color="danger"
                        onClick={(e) => deleteHandler(e, patient)}
                        // onClick={() => deleteHandler(patient.id)}
                      >
                        Delete
                      </Button>
                      <Button
                        color="success"
                        onClick={(e) => {
                          appointmentModalHandler(e, patient?.Id);
                        }}
                      >
                        Appointments
                      </Button>
                    </td>
                  </tr>
                ))
              ) : loading ? (
                <TableSkelton Rows={5} />
              ) : (
                <NoDataFound />
              )}
            </tbody>
          </Table>
        </Card>
      </Container>

      {/* Add Patient Modal */}
      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>{Add} Patient</ModalHeader>
        <ModalBody>
          <Form onSubmit={formik.handleSubmit}>
            <FormGroup>
              <Label for="name">Name</Label>
              <Input
                type="text"
                id="name"
                name="PatientName"
                placeholder="Enter Patient name"
                value={formik.values.PatientName}
                onChange={formik.handleChange}
              />
              {formik.touched.PatientName && formik.errors.PatientName && (
                <small className="text-danger">
                  <GoDotFill size={20} /> {formik.errors.PatientName}
                </small>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                type="email"
                id="email"
                name="Email"
                placeholder="Enter Patient email"
                value={formik.values.Email}
                onChange={formik.handleChange}
              />
              {formik.touched.Email && formik.errors.Email && (
                <small className="text-danger">
                  <GoDotFill size={20} /> {formik.errors.Email}
                </small>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="PhoneNo">PhoneNo</Label>
              <PhoneInput
                country={"us"}
                id="PhoneNo"
                name="PhoneNo"
                placeholder="Enter Patient PhoneNo"
                value={formik.values.PhoneNo}
                onChange={(value, data, event, formattedValue) => {
                  formik.setFieldValue("PhoneNo", formattedValue);
                }}
              />
              {formik.touched.PhoneNo && formik.errors.PhoneNo && (
                <small className="text-danger">
                  <GoDotFill size={20} /> {formik.errors.PhoneNo}
                </small>
              )}
            </FormGroup>
            <ModalFooter>
              <Button
                className="btn-width-full-sm"
                disabled={loading}
                color="primary"
                type="submit"
              >
                {Add} Patient{" "}
                {loading && <ImSpinner8 className="spinning-icon" />}
              </Button>
              <Button
                className="btn-width-full-sm"
                color="danger"
                onClick={()=>{
                  toggleModal()
                  formik.resetForm()
                }}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </ModalBody>
      </Modal>
      {AppointModal && (
        <AppointmentModal
          appointmentsModalOpen={appointmentsModalOpen}
          AppointmentedPerson={AppointmentedPerson}
          closeModal={closeModal}
        />
      )}

      {/* <SessionStart  modal={modal}  toggle={toggle} /> */}
      {openModal && (
        <DeleteModal
          data={doctId}
          DeleteHandler={confirmDelete}
          loading={loading}
          closeModal={CloseModal}
        />
      )}
    </>
  );
};

export default Patients;
