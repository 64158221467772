

import Register from 'views/auth/Register.js';
import Login from 'views/auth/Login.js';
import OtpVerify from 'views/auth/OtpVerification';
import PasswordUpdate from 'views/auth/PasswordUpdate';
import Patients from 'views/Patients';
import Appointments from 'views/Appointments';
import Docs from 'views/Docs';
import SessionTabs from 'views/SessionTabs';

var routes = [
	{
		path: '/patients',
		name: 'Patients',
		icon: 'ni ni-tv-2 text-primary',
		component: Patients,
		layout: '/admin',
	},
	{
		path: '/appointments',
		name: 'Appointments',
		icon: 'ni ni-planet text-blue',
		component: Appointments,
		layout: '/admin',
	},
	// {
	// 	path: '/sessions',
	// 	name: 'Sessions',
	// 	icon: 'ni ni-pin-3 text-orange',
	// 	component: Sessions,
	// 	layout: '/admin',
	// },
	{
		path: '/sessions',
		name: 'Sessions',
		icon: 'ni ni-pin-3 text-orange',
		component: SessionTabs,
		layout: '/admin',
	},
	{
		path: '/docs',
		name: 'Docs',
		icon: 'ni ni-single-02 text-yellow',
		component: Docs,
		layout: '/admin',
	},
	// {
	// 	path: '/tables',
	// 	name: 'Tables',
	// 	icon: 'ni ni-bullet-list-67 text-red',
	// 	component: Tables,
	// 	layout: '/admin',
	// },
	{
		path: '/login',
		name: 'Login',
		icon: 'ni ni-key-25 text-info',
		component: Login,
		layout: '/auth',
		isMenu: false,
	},
	{
		path: '/register',
		name: 'Register',
		icon: 'ni ni-circle-08 text-pink',
		component: Register,
		layout: '/auth',
		isMenu: false
	},
	{
		path: '/verification',
		name: 'OTP Verifcation',
		icon: 'ni ni-circle-08 text-pink',
		component: OtpVerify,
		layout: '/auth',
		isMenu: false
	},
	{
		path: "/password",
		name: "Update Password",
		icon: "ni ni-key-25 text-info",
		component: PasswordUpdate,
		layout: "/admin",
		isMenu: false,
	  },
];
export default routes;
