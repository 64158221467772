/* eslint-disable react-hooks/exhaustive-deps */
import "../../assets/scss/SettingsModal.scss";
import React from "react";
import { Spinner } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";
import {
  getSettingAction,
  updateSettingAction,
} from "store/actions/SettingAction";

//This modal will have two things;
//1. An input for delay timer
//2. A button to save video
const SettingsModal = ({ toggleSettingsModal, settingsModal }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state?.setting);
  const { user } = useSelector((state) => state?.authUser);
  //code related to getting settings

  const DoctorID = user?.DoctorId;
  React.useEffect(() => {
    dispatch(
      getSettingAction(DoctorID, () => {
        
      })
    );
  }, []);

  const { DelayTimer, SaveVideo } = useSelector(
    (state) => state?.setting?.getSetting
  );

  // console.log("kkkkk>>>",DelayTimer,SaveVideo)

  // const { savevideo } = useSelector((state) => state.setting);

  const [localDelayTimer, setLocalDelayTimer] = React.useState(0);
  const [localSaveVideo, setLocalSaveVideo] = React.useState(false);
  // console.log(delaytimer, savevideo)
  // console.log(localDelayTimer, localSaveVideo)

  React.useEffect(() => {
    setLocalDelayTimer(DelayTimer || 0);
    setLocalSaveVideo(SaveVideo || false);
  }, [DelayTimer, SaveVideo]);

  // console.log(user)

  //code related to updating setting

  const handleUpdate = () => {
    const payload = {
      DoctorId: DoctorID,
      DelayTimer: parseInt(localDelayTimer),
      SaveVideo: true,
    };

    dispatch(
      updateSettingAction(payload, (e) => {
        toggleSettingsModal();
      })
    );
  };

  return (
    <>
      <Modal isOpen={settingsModal} toggle={toggleSettingsModal} backdrop>
        <ModalHeader toggle={toggleSettingsModal}>Settings</ModalHeader>
        <ModalBody>
          <div className="form-group">
            <label htmlFor="delay">Delay (in seconds)</label>
            <input
             style={{ appearance: "textfield" }}
              type="number"
              className="form-control"
              id="delay"
              placeholder="Enter delay"
              value={localDelayTimer}
              onChange={(e) => {
                // Allow only non-negative values
                const inputValue = Math.max(0, parseInt(e.target.value));
                setLocalDelayTimer(inputValue);
              }}
              min={0}
            />
          </div>

          {/* <div className="save-video d-flex justify-content-between align-items-center  ">
            <div>Save Video?</div>
            <div>
              <label className="switch  ">
                <input
                  type="checkbox"
                  id="save"
                  checked={localSaveVideo}
                  onChange={() => setLocalSaveVideo(!localSaveVideo)}
                />
                <div className="slider"></div>
                <div className="slider-card">
                  <div className="slider-card-face slider-card-front"></div>
                  <div className="slider-card-face slider-card-back"></div>
                </div>
              </label>
            </div>
          </div> */}
        </ModalBody>

        <ModalFooter>
          <Button onClick={toggleSettingsModal} color="danger">Cancel</Button>
          {/* Conditional rendering for "Update" or "Save" button */}
          <Button
            onClick={() => {
              handleUpdate();
            }}
            disabled={loading}
            color="primary"
          >
            Save
            {loading && <Spinner size="sm" />}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default SettingsModal;
