import React from "react";
import { ImSpinner8 } from "react-icons/im";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

const DeleteModal = (props) => {
  return (
    <>
      <Modal isOpen={true} >
        <ModalHeader>ALERT!</ModalHeader>
        <ModalBody>
          <h5 className="text-center ">
            Are you sure you want to delete?
          </h5>

          <div className="d-flex justify-content-center align-items-center my-3">
            <Button disabled={props?.loading} className="bg-primary text-white w-25 mx-2" onClick={props?.DeleteHandler}>
              Yes {props?.loading && <ImSpinner8 className="spinning-icon" />}</Button>
              

            <Button
              className="bg-danger text-white w-25"
              onClick={props?.closeModal}
            >
              No
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
export default DeleteModal;
